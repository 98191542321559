@import "../../styles/colors.css";
@import "../../styles/icons/variables.css";

.accountIcons {
  composes: darkIcon from "~styles/icons/styles.css";
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  mask-size: 20px 20px;
  mask-position: 15px center;
}

.multiColorAccountIcons {
  composes: accountIcons;
  background-image:  var(--icon);;
  background-size: 20px 20px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-color: transparent;
}

.MyAccount {
  --icon: svg-inline(accountUnfilled);
  
  composes: accountIcons;
}

.MyOrders {
  --icon: svg-inline(orders);
  
  composes: accountIcons;
}

.MyReturns {
  --icon: svg-inline(returns);
  
  composes: accountIcons;
}

.ReturnsInformation {
  --icon: svg-inline(returnsInformation);
  
  composes: accountIcons;
}

.ContactPreferences {
  --icon: svg-inline(contactPreferences);

  composes: accountIcons;
}


/* Account Holiday Occasions */

.MyAccountChristmasLoggedIn {
  --icon: svg-inline(accountChristmasFilledDark);

  composes: multiColorAccountIcons;
}

.MyAccountChristmasLoggedOut {
  --icon: svg-inline(accountChristmasUnfilledDark);

  composes: multiColorAccountIcons;
}

a:hover > .MyAccountChristmasLoggedIn {
  background-color: var(--blue);
  background-image: none;
}

a:hover > .MyAccountChristmasLoggedOut {
  background-color: var(--blue);
  background-image: none;
}

.MyAccountStPatricksLoggedOut {
  --icon: svg-inline(accountStPatricksUnfilledDark);
  
  composes: multiColorAccountIcons;
}

.MyAccountStPatricksLoggedIn {
  --icon: svg-inline(accountStPatricksFilledDark);

  composes: multiColorAccountIcons;  
}

a:hover > .MyAccountStPatricksLoggedOut {
  background-color: var(--blue);
  background-image: none;
}

a:hover > .MyAccountStPatricksLoggedIn {
  background-color: var(--blue);
  background-image: none;
}

.MyAccountEasterLoggedOut {
  --icon: svg-inline(accountEasterUnfilledDark);
  
  composes: multiColorAccountIcons;
}

.MyAccountEasterLoggedIn {
  --icon: svg-inline(accountEasterFilledDark);

  composes: multiColorAccountIcons;
}

a:hover > .MyAccountEasterLoggedOut {
  background-color: var(--blue);
  background-image: none;
}

a:hover > .MyAccountEasterLoggedIn {
  background-color: var(--blue);
  background-image: none;
}

.MyAccountHalloweenLoggedOut {
  --icon: svg-inline(accountHalloweenUnfilledDark);
  
  composes: multiColorAccountIcons;
}

.MyAccountHalloweenLoggedIn {
  --icon: svg-inline(accountHalloweenFilledDark);
  
  composes: multiColorAccountIcons;
}

a:hover > .MyAccountHalloweenLoggedOut {
  background-color: var(--blue);
  background-image: none;
}

a:hover > .MyAccountHalloweenLoggedIn {
  background-color: var(--blue);
  background-image: none;
}

@import "../../styles/colors.css";
@import "../../styles/icons/variables.css";

.close {
  --icon: svg-inline(close);

  composes: blackIcon from "~styles/icons/styles.css";
  cursor: pointer;
  margin: 0px 5px;
  padding: 0 16px;
  mask-size: 19px;
}

.close:hover {
  background-color: var(--blue);
}

@import "../../../../styles/colors.css";
@import "../../../../styles/icons/variables.css";

.deleteItem {
  position: absolute;
  width: 2.9rem;
  height: 2.9rem;
  right: -0.7rem;
  mask-size: 1rem 1.25rem;
  border: none;
  bottom: 0.2rem;
  z-index: 1;
  background-color: transparent;
}

.deleteItemIcon {
  --icon: svg-inline(bin);
  
  composes: greyIcon from "~styles/icons/styles.css";
  margin-top: 2px;
}

.deleteItemIcon:hover {
  background-color: var(--red);
}

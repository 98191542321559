@import "../../../styles/colors.css";
@import "../../../styles/viewports.css";
@import "../../../styles/zindex.css";

.modal {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--modal-z-index);
}

.modal.center {
  bottom: 0;
  left: 0;
  margin: auto;
  display: none;
}

.modal.center.isOpen {
  display: block;
}

.modal.side {
  transform: translateX(100%);
  transition: transform 0.5s linear, visibility 0.5s linear;
  animation-name: slideOpen;
  animation-duration: 0.5s;
  visibility: hidden;
}

.modal.side.isOpen {
  transform: translateX(0);
  visibility: visible;
}

@media (--medium-viewport) {
  .modal.center {
    max-width: 600px;
    max-height: 560px;
  }

  .modal.center.large {
    max-width: 900px;
    max-height: calc(100% - 120px);
  }

  .modal.side {
    max-width: 375px;
  }
}

@keyframes slideOpen {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}

@import "../../../../styles/colors.css";
@import "../../../../styles/zindex.css";

.pageOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-page-overlay-z-index);
  background-color: var(--black);
  opacity: 0.7;
}

.pageOverlay.side {
  transition: opacity 0.5s linear;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

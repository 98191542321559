.sbCloseIcon {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAC70lEQVRYCdWYP44aMRSHTTRapKRKm2KPMBEF0tJsiZSbIMEFOAEXAImzUKZhJQokjrBF2lSJxAqJvG/Wj/XMGPCMTbJ5kpk/tn/vm+exxzxj/lc7Ho8dKZ9uzY8PfJ3z88FXYTvkUvco5599bVLcs9qPopWfg6yRO3D3FuIgx6dOp/MzBZRqWLgHuc7svWc57sTPUdtwLEXQhdvv92az2dAGgQcryHW0uXD4wJcYAalF8gRYhRuPx2Y0GpnVakXnZJAuHNr4wNc5yGKIfXA2eibLMjObzcxwOAQ0arircNPp1BwOSBrT7/fNYrEw3W6Xy9NwawQ/ys0v1Ox2O7PdbjktDAGEYiN5CQ5H+MS3NVhgen0H5cX8JedPUg48CREjcmqxkNfgdJTwDYMUJiVMpjSLQ4WaDHesZgkQ4lhBNNRSaNUAEU8hnEIDFi9gLGQquIuAbSHpJ1Z8IZj57lJChU6I0Pf4bAQRw1pEg25ZCjiErgLSqCFksWbGRg6/WBAgDUMg5/P5C20nk8mdfiG4bjqs9FELBqRDCCTtUsGh1QiQDtcgaaMWEznVaAxIRxdyvV6/VIeUNsAx5IPB4E4u9fPVeE+pmwU036U1BnSjx1Liix5PyntIXewuqNEQV+F8S4nC6XDEvofBgCFwt1hmgoY4BI49JBOCknI/eTWCoXDOt5XR/TufuhZw7Mqx228W2sDpf+eYvq/P9/brHeIUDlJogFkDTCWMeAqtEmAKQcBci9U8AcYKuVDV8xjtAlAESLORZcrIKJCOSLllAjgEcrlcFhkGac7m4jv/jXWh/i03fkgxeZ6bXq/HaWGxnyrVsTO8SA6wZlYXc3zi2xosML1NEnlCokmLexI5JHRIRyDkLMLRaThfJIHz5WVKgFxUIcmVVNIRjfdz6FbNheSVInLVpJH2OU0SveFC2nutN5uq6Tu6kLb+WY61BKavbxFJEfgq5ZsV8raLvYm29YGvWrAu6tNByj9Pol+EfA+VfwC3G2nOmCndTwAAAABJRU5ErkJggg==");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 23px;
}

.asosNameIcon::after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAAAYCAYAAAAGcjT5AAADJ0lEQVRoBe2av2sUQRTHv3OeEbRQsPGQ9GqnBlLY2NgZQwKxEiwEfxUWaiGkEDsL0U7Q2iIoJCRnZ20X8H9Q5LQQtFBBo+N35n7s3N7s7cy5J+7wBib79u178z3efrI7O7tARNObmDM9IqXSUNGvX/2bUQQ0cK0Xfykqr6pg0a9d/VXoudcbOIAm3tv4HRxWS/gcmltFnOjXs/6N4JPfxEUo7LXd2P+6iX4t6x8OGHDVYcq1HfdUTVfTtacq6gzuarq2EzJV09V07amKOoO7mq7thIyaQYDpNk7zynVkkE7b+gaO6RqiX9/6BwFGuPqTy4wkny87Wq3l0/L5qlXNRvNp+XxZRrWWT8vnq1Y1G82n5fNlGQOrdJKvn+MQZ15vmbF7kNU1fuIXZtUiPub8le6Kfr3rPwBMr+MgZtAiNC002BW7tluz7nWqgJrXjNlmbIfbDn6z72L/gY5axqeCHK9b9NOsv9IvcY9w3CEkM94zP6lTEzOF++os7o4bQvQTr78GMWjjAf/eHAdC9DGNh1jAbV4iKVHcRD/t+jcMAGoBt4jAajEG0UdWzZhlcJlRRT/t+vP8Zk1v4QrP+GP2sKfLLLVrac7CNK6rc3iSPxSyL/rp1X8IMAMB50QrhOQZIYubk3XnXBc453oRAlNRjOinVf8RwCxkmzjDp8EN2vuKQMj5v/Lpc4lLFq9y/ol2+dWE6CdSf++tsAfKWgQda1XBZTRF3/6jJlF/L2A9sE5GABYTGzpszJgxsaIfVoGYmhbG+m+RbfvVxBf+jtDvxXY4b9vPJ8dvYb99fBTfPZqvNkQ/gfoXXcFORMBlaDEgmpyqmuiHw2Vq/t/W3w+YwryXFM2Jv+m+VpTjiy3zFY0l+rWrvx8w5ADTeEcmFnkLXDbd2ATN+Nzmh9KNCLeHxxL92tbfD5juAaa5+AA8wncc4/rWVp8Pa9Nnj3VjzAuhYSj6wZNs+2OJfu3rPzLJ730e0yEX28TrMpcM3oxjhGtWx7lm9pQxc5zit9R5fBgXX3ZM9O3nOcnUf/QKtgdH+cLnBvGaL4PLwGJjGGtzTO7fNtFPqv5/AL1CdOdAPjogAAAAAElFTkSuQmCC");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 82px;
  background-origin: border-box;
}

@import "../../styles/icons/variables.css";

.widgetMyAccountLoggedIn {
  background-image: svg-inline(accountFilled);
  background-size: contain;
}

.widgetMyAccountChristmasLoggedOut {
  background-image: svg-inline(accountChristmasUnfilled);
  background-size: contain;
}

.widgetMyAccountChristmasLoggedIn {
  background-image: svg-inline(accountChristmasFilled);
  background-size: contain;
}

.widgetMyAccountStPatricksLoggedOut {
  background-image: svg-inline(accountStPatricksUnfilled);
  background-size: contain;
}

.widgetMyAccountStPatricksLoggedIn {
  background-image: svg-inline(accountStPatricksFilled);
  background-size: contain;
}

.widgetMyAccountEasterLoggedOut {
  background-image: svg-inline(accountEasterUnfilled);
  background-size: contain;
}

.widgetMyAccountEasterLoggedIn {
  background-image: svg-inline(accountEasterFilled);
  background-size: contain;
}

.widgetMyAccountHalloweenLoggedOut {
  background-image: svg-inline(accountHalloweenUnfilled);
  background-size: contain;
}

.widgetMyAccountHalloweenLoggedIn {
  background-image: svg-inline(accountHalloweenFilled);
  background-size: contain;
}

.widgetSavedItemsFilled {
  background-image: svg-inline(savedItemsFilled);
  background-size: contain;
}

.widgetSavedItemsPrideFilled {
  background-image: svg-inline(savedItemsPrideFilled);
  background-size: contain;
}

.widgetSavedItemsValentinesUnfilled {
  background-image: svg-inline(savedItemsValentinesUnfilled);
  background-size: contain;
}

.widgetSavedItemsValentinesFilled {
  background-image: svg-inline(savedItemsValentinesFilled);
  background-size: contain;
}

.widgetBagFilled {
  background-image: svg-inline(bagFilled);
  background-size: contain;
}

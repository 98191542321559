@import "../../../../../styles/colors.css";
@import "../../../../../styles/zindex.css";

.container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: var( --modal-overlay-z-index);
  background-color: var(--white);
}

.progressIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

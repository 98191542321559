@import "../../../styles/colors.css";

.container {
  display: inline-block;
}

.current {
  composes: london3 from "~styles/typography/styles.css";
  display: block;
  color: var(--black);
}

.highlight {
  color: var(--red);
}

.previous,
.rrp {
  composes: leavesden4 from "~styles/typography/styles.css";
  display: block;
  color: var(--warm-grey);
  text-decoration: line-through;
}

.rrpLabel::after {
  content: " ";
}

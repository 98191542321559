@import "../../../../../../styles/colors.css";

.main {
  color: var(--black);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 80%;
}

.errorMessage {
  padding: 24px;
  text-align: center;
}

.face {
  display: block;
  height: 88px;
  margin: 0 auto;
  max-width: 100%;
  width: 88px;
}

.heading {
  composes: london2 from "~styles/typography/styles.css";
  margin: 20px 20px 8px;
  text-transform: uppercase;
}

.para {
  composes: leavesden3 from "~styles/typography/styles.css";
  max-width: 480px;
}
@import "../../../styles/icons/variables.css";

.Facebook {
  background-image: svg-inline(facebook);
}

.Instagram {
  background-image: svg-inline(instagram);
}

.Snapchat {
  background-image: svg-inline(snapchat);
}

.Vk {
  background-image: svg-inline(vk);
}

@import "../../../styles/colors.css";

:root {
  --name-height: 1.25em;
}

.container {
  list-style: none;
  padding: 1em 0;
  margin: 0 1em;
  border-top: 1px solid var(--grey-10);
}

.container.product:hover *, .container.voucher:hover * {
  color: var(--blue);
}

li:first-child .container {
  border-top: none;
}

.link {
  composes: leavesden2 from "~styles/typography/styles.css";
  text-decoration: none;
  display: flex;
  flex-direction: row;
}

.image {
  margin-right: 1em;
  width: 109px;
  align-self: flex-start;
  flex-shrink: 0;
}

.details {
  composes: leavesden3 from "~styles/typography/styles.css";
  color: var(--grey-90);
  padding: 4px 0px;
  margin-bottom: 1.125em;
  flex-shrink: 1;
}

.price {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
}

.item {
  position: relative;
}

.name {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: var(--name-height);
  margin-bottom: 8px;
  color: var(--grey-90);
}

.name::after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3em;
  height: var(--name-height);
  background: linear-gradient(90deg, transparent, var(--white));
  content: "";
}

.item.product .name {
  height: 2.5em;
}

.item.product .image {
  height: 139px;
}

.item.subscription .details {
  width: 100%;
}

.meta {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  color: var(--grey-90);
}

.colour,
.size,
.quantity {
  flex: 1 0 auto;
  box-sizing: border-box;
  padding-right: 1px;
}

.meta dd {
  margin: 0;
  white-space: nowrap;
}

.quantity abbr {
  text-decoration: none;
  white-space: nowrap;
}

.quantity dt {
  display: inline;
}

.quantity dt::after {
  content: ": ";
}

.quantity dd {
  display: inline;
}

.srOnly {
  composes: srOnly from "~styles/accessibility.css"
}
@import "../../../../../../styles/zindex.css";

.button {
  position: absolute;
  right: 16px;
  top: 16px;
  border: none;
  width: 17px; 
  height: 17px;
  cursor: pointer;
  background-color: transparent;
  padding: 14px;
  z-index: var(--modal-close-button-z-index);
}

.icon {
  width: 15px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


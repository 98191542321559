@import "../../styles/colors.css";

:root {
  --slide-over-duration: 1000ms;
  --item-deleted-label-animation-duration: 2150ms;
}

.wrapper {
  overflow: hidden;
}

.transitionList__element {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.transitionList__overlay {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--grey-40);
  transform: translateX(100%);
  transition: transform var(--slide-over-duration);
}

.transitionList__element--exiting .transitionList__overlay {
  transform: translateX(0);
  z-index: 2;
}


.transitionList_itemDeletedLabel {
  composes: leavesden2 from "~styles/typography/styles.css";
  color: var(--black);
  height: 0;
}

@keyframes scaler {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  15% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.transitionList__element--exiting .transitionList_itemDeletedLabel {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 3;
  animation-name: scaler;
  animation-duration: var(--item-deleted-label-animation-duration);
}
